/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldRule,
    FieldRuleFromJSON,
    FieldRuleFromJSONTyped,
    FieldRuleToJSON,
    MetaformField,
    MetaformFieldFromJSON,
    MetaformFieldFromJSONTyped,
    MetaformFieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface MetaformSection
 */
export interface MetaformSection {
    /**
     * 
     * @type {string}
     * @memberof MetaformSection
     */
    title?: string;
    /**
     * 
     * @type {FieldRule}
     * @memberof MetaformSection
     */
    visibleIf?: FieldRule;
    /**
     * 
     * @type {Array<MetaformField>}
     * @memberof MetaformSection
     */
    fields?: Array<MetaformField>;
}

export function MetaformSectionFromJSON(json: any): MetaformSection {
    return MetaformSectionFromJSONTyped(json, false);
}

export function MetaformSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'visibleIf': !exists(json, 'visible-if') ? undefined : FieldRuleFromJSON(json['visible-if']),
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(MetaformFieldFromJSON)),
    };
}

export function MetaformSectionToJSON(value?: MetaformSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'visible-if': FieldRuleToJSON(value.visibleIf),
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(MetaformFieldToJSON)),
    };
}


