/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaformFieldPermissionContexts
 */
export interface MetaformFieldPermissionContexts {
    /**
     * Field value is used as security group with view permission
     * @type {boolean}
     * @memberof MetaformFieldPermissionContexts
     */
    viewGroup?: boolean;
    /**
     * Field value is used as security group with edit permission
     * @type {boolean}
     * @memberof MetaformFieldPermissionContexts
     */
    editGroup?: boolean;
    /**
     * Field value is used as security group with notification permission
     * @type {boolean}
     * @memberof MetaformFieldPermissionContexts
     */
    notifyGroup?: boolean;
}

export function MetaformFieldPermissionContextsFromJSON(json: any): MetaformFieldPermissionContexts {
    return MetaformFieldPermissionContextsFromJSONTyped(json, false);
}

export function MetaformFieldPermissionContextsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformFieldPermissionContexts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'viewGroup': !exists(json, 'view-group') ? undefined : json['view-group'],
        'editGroup': !exists(json, 'edit-group') ? undefined : json['edit-group'],
        'notifyGroup': !exists(json, 'notify-group') ? undefined : json['notify-group'],
    };
}

export function MetaformFieldPermissionContextsToJSON(value?: MetaformFieldPermissionContexts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'view-group': value.viewGroup,
        'edit-group': value.editGroup,
        'notify-group': value.notifyGroup,
    };
}


