import * as React from "react";

import styles from "../../styles/form";

import { WithStyles, withStyles, Icon, LinearProgress, Slider } from "@material-ui/core";
import { Metaform } from "../../generated/client";
import { MetaformComponent, FieldValue, IconName } from "metaform-react";
import DatePicker from "react-datepicker";
import AddIcon from '@material-ui/icons/Add';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import strings from "../../localization/strings";
import { FileFieldValueItem, ValidationErrors } from "metaform-react/dist/types";
import "react-datepicker/dist/react-datepicker.css";

/**
 * Component props
 */
interface Props extends WithStyles<typeof styles> {
  contexts: string[];
  metaform: Metaform;
  ownerKey?: string
  getFieldValue: (fieldName: string) => FieldValue;
  setFieldValue: (fieldName: string, fieldValue: FieldValue) => void;
  onSubmit: (source: Metaform) => void;
  onValidationErrorsChange?: (validationErrors: ValidationErrors) => void;
}

/**
 * Component state
 */
interface State {
  uploadingFields: string[]
}

/**
 * Component for exhibitions screen
 */
export class Form extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {    
      uploadingFields: []  
    };
  }

  /**
   * Component render method
   */
  public render = () => {
    const { classes, metaform, onValidationErrorsChange } = this.props;

    return (
      <div className={ classes.formContainer }>
        <MetaformComponent
          strings={ strings.form }
          form={ metaform } 
          contexts={ this.props.contexts }
          formReadOnly={ false }
          getFieldValue={ this.props.getFieldValue }
          setFieldValue={ this.props.setFieldValue }
          datePicker={ this.renderDatePicker }
          datetimePicker={ this.renderDatetimePicker }
          uploadFile={ this.uploadFile }
          onFileDelete={ this.deleteFile }
          onFileShow={ this.showFile }
          setAutocompleteOptions={ this.setAutocompleteOptions }
          renderIcon={ this.renderIcon }        
          renderSlider={ this.renderSlider }
          onSubmit={ this.props.onSubmit }
          onValidationErrorsChange={ onValidationErrorsChange }
          renderBeforeField={(fieldname) => {
            if (fieldname && this.state.uploadingFields.indexOf(fieldname) > -1) {
              return (<LinearProgress />);
            }
          }}
        />
      </div>
    );
  }

  /**
   * Method for setting date
   *
   * @param onChange on change callback for setting date
   */
  private renderDatePicker = (fieldName: string, onChange: (date: Date) => void) => {
    const value = this.props.getFieldValue(fieldName);
    return (
      <DatePicker
        selected={ value ? new Date(value as string) : null }
        onChange={ onChange }
        dateFormat="dd.MM.yyyy"
        locale="fi"
      />
    );
  }

  /**
   * Method for setting datetime
   *
   * @param onChange on change callback for setting datetime
   */
  private renderDatetimePicker = (fieldName: string, onChange: (date: Date) => void) => {
    const value = this.props.getFieldValue(fieldName);
    return (
      <DatePicker
        selected={ value ? new Date(value as string) : null }
        onChange={ onChange }
        dateFormat="dd.MM.yyyy"
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={ 15 }
        locale="fi"
      />
    );
  }

  /**
   * Renders slider field
   * 
   * @param fieldName field name
   * @param readOnly whether the field is read only
   */
  private renderSlider = (fieldName: string, readOnly: boolean) => {
    const { setFieldValue } = this.props; 
    const field = this.getField(fieldName);
    if (!field) {
      return null;
    }

    const value = this.props.getFieldValue(fieldName);
    
    return (
      <Slider 
        step={ field.step }
        max={ field.max }
        min={ field.min }
        name={ field.name }
        placeholder={ field.placeholder }
        disabled={ readOnly }
        value={ value as number }
        valueLabelDisplay="auto"
        onChange={ (_event: React.ChangeEvent<{}>, value: number | number[]) => {
          setFieldValue(fieldName, value as number);
        }}
      />
    );
  }

  /**
   * Finds a field from form by field name
   * 
   * @param fieldName field name
   * @returns field or null if not found
   */
  private getField = (fieldName: string) => {
    const { metaform } = this.props;

    return (metaform.sections || [])
      .flatMap(section => section.fields || [])
      .find(field => field.name === fieldName);
  }

  /**
   * Method for uploading a file
   *
   * @param file file
   * @param path path
   */
  private uploadFile = (fieldName: string, files: FileList | File, path: string) => {
    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i++) {
        let item = files.item(i);
        if (item) {
          console.log("Uploading file");
        }
      }
    } else if (files instanceof File) {
      console.log("Uploading file");
    }
  }

  /**
   * Deletes uploaded file
   * Only unsecure (not yet persisted) files can be deleted, otherwise they are just removed from data
   * 
   * @param fieldName field name
   * @param value uploaded value
   */
  private deleteFile = (fieldName: string, value: FileFieldValueItem) => {
    console.log("Deleting file");
  }

  /**
   * Shows uploaded file
   * 
   * @param fieldName field name
   * @param value uploaded value
   */
  private showFile = async (fieldName: string, value: FileFieldValueItem) => {
    console.log("Showing uploaded file");
  }

  /**
   * Method for setting autocomplete options
   *
   * @param path path
   */
  private setAutocompleteOptions = async (path: string, input?: string): Promise<string[]> => {
    return [];
  }
  
  /**
   * Method for rendering form icons
   *
   * @param icon icon name
   * @param key key
   */
  private renderIcon = (icon: IconName, key: string): React.ReactNode => {
    switch (icon) {
      case "add":
        return <AddIcon/>
      case "check-square-o":
        return <CheckBoxIcon/>
      case "square-o":
        return <CheckBoxOutlineBlankIcon/>
    }

    return <Icon className={ icon } />;
  }

}

export default withStyles(styles)(Form);
