import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme();

export default createMuiTheme({

  overrides: {
    MuiCssBaseline: {
      // Global css overrides
      "@global": {
        "a": {
          textDecoration: "none"
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "#3c3c3c"
        },
        "::-webkit-scrollbar": {
          height: 5,
          width: 8
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "#f9473b",
          borderColor: "#3c3c3c",
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 8
        }
      }
    }
}
});