import { createStyles } from "@material-ui/core";

export default createStyles({

  formContainer: {
    "& .metaform": {
      fontSize: "1rem",
      width: "70%",
      margin: "auto",
      "@media only screen and (max-width: 768px)": {
        width: "100%"
      },

      "& .fieldset": {
        padding: "0px !important",
        margin: "0px !important"
      },

      "& .metaform-section": {
        padding: "32px",
        margin: "16px 0px 16px 0px",
        backgroundColor: "#fff",
        borderRadius: "25px",
        border: "1px solid #4F4F4F",
        boxSizing: "border-box",
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
        "@media only screen and (max-width: 768px)": {
          padding: "32px 16px !important",
          margin: "16px 0px 16px 0px !important",
          borderRadius: "8px !important"
        }
      },

      "& .metaform-section:nth-of-type(1), .metaform-section:nth-of-type(5)": {
        textAlign: "center",
        padding: "0px",
        margin: "0px",
        backgroundColor: "transparent",
        border: "0px",
        boxShadow: "none",
        paddingTop: "0px !important",
      },

      "& .metaform-section:nth-of-type(1)": {
        "& img": {
          borderRadius: "0px !important",
          border: "0px !important",
          width: "50%",
          "@media only screen and (max-width: 768px)": {
            width: "100%"
          }
        }
      },

      "& .metaform-section:nth-of-type(5)": {
        textAlign: "right !important",
        "& img": {
          borderRadius: "0px !important",
          border: "1px solid #fff !important",
          width: "25%",
          "@media only screen and (max-width: 768px)": {
            width: "50%"
          }
        }
      },

      "& .metaform-section:nth-of-type(3)": {
        "& div": {
          display: "flex"
        }
      },

      "& .metaform-section:nth-of-type(2)": {
        display: "flex",
        flexDirection: "column",
        "& h2": {
          flex: "1"
        },
        "& fieldset": {
          flex: "1",
          display: "flex",
          flexWrap: "wrap",
          gap: "5%",
          "& .metaform-field": {
            flex: "1",
            minWidth: "42.5%",
            "@media only screen and (max-width: 768px)": {
              minWidth: "100%"
            },
          },
          "& .metaform-field:nth-of-type(1), .metaform-field:nth-of-type(4), .metaform-field:nth-of-type(5)": {
            flex: "1",
            minWidth: "100%"
          }
        }
      },

      "& .react-datepicker-wrapper": {
        width: "100%"
      },

      "& .metaform-section:nth-of-type(4)": {
        backgroundColor: "#1f1f1f",
        color: "#fff",
        border: "0.5px solid #fff",
        borderRadius: "0px 50px !important",
        background: "linear-gradient(333deg, #1fbcdb 0%, rgba(5,5,36,1) 50%, #f9473b 100%)",
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px #1fbcdb, 0px 1px 14px rgba(0, 0, 0, 0.12)",
        "& h2": {
          fontFamily: "'Staatliches'"
        },
        "& p": {
          fontFamily: "'Montserrat'"
        }
      },

      "& textarea": {
        width: "100%",
        border: "1px solid rgba(0, 0, 0, .15)",
        borderRadius: "0.25rem",
        height: "150px",
        padding: "8px"
      },

      "& label": {
        marginBottom: "0px",
        fontSize: "0.8em"
      },

      "& row section": {
        backgroundColor: "#fff"
      },

      "& .field-input": {
        width: "50%"
      },

      "& .field-input label": {
        fontSize: "14px"
      },

      "& th": {
        fontSize: "14px !important",
        fontWeight: "100 !important",
      },

      "& p": {
        marginTop: "0px !important",
        marginBottom: "0px !important"
      },

      "& h1, & h2, & h3": {
        marginBottom: "8px !important",
        fontWeight: "100 !important",
        fontFamily: "'Roboto Slab', Sans-Serif",
        marginTop: "0px !important"
      },

      "& h1": {
        fontSize: "2em",
        lineHeight: "1em"
      },

      "& h1:nth-of-typ(1)": {
        marginBottom: "0",
      },

      "& h2": {
        fontSize: "1.3em"
      },
  
      "& h3": {
        fontSize: "1.1rem"
      },

      "& .metaform-field": {
        marginTop: 10
      },

      "& fieldset": {
        border: "none",
        padding: "0px !important",
        margin: "0px !important"
      },
  
      "& input[type='text'], & input[type='number'], & input[type='email'], & select": {
        width: "100%",
        border: "0px",
        borderBottom: "1px solid #a8a8a8a8"
      },

      "& input:focus-visible": {
        outline: "none",
        borderBottom: "1px solid #f9473b"
      },

      "& .metaform textarea:focus": {
        outline: "#f9473b"
      },

      "& input::placeholder, & select::placeholder": {
        color: "#6c757d"
      },
      
      "& input[type='submit']": {
        padding: "10px 20px",
        fontSize: "18px",
        borderRadius: "0.3rem",
        border: 0,
        background: "#007bff",
        color: "#fff"
      },

      "& input[type='file' i]": {
        padding: "10px 20px",
        fontSize: "18px",
        borderRadius: "0.3rem",
        border: 0,
        background: "#007bff",
        color: "#fff"
      },

      "& input[type='submit']:hover": {
        background: "#0069d9",
      },

      "& .MuiSlider-root": {
        color: "#f9473b"
      },

      "& table input": {
        borderRadius: "4px !important",
        borderWidth: "1px",
        borderColor: "rgba(0, 0, 0, .15)"
      },

      "& table input:focus": {
        borderRadius: "25px !important",
        border: "1px solid #f9473b !important",
      },

      "& img": {
        borderRadius: "10px !important",
        border: "1px solid #fff !important",
        width: "100%",
        "@media only screen and (max-width: 768px)": {
          borderRadius: "0px !important"
        },
      }
    }
  },

});