import * as React from "react";

import { ThemeProvider } from "@material-ui/styles";
import metaformTheme from "../styles/theme";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CssBaseline, responsiveFontSizes } from "@material-ui/core";
import strings from "../localization/strings";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";
import FormScreen from "./screens/form-screen";

/**
 * Interface representing component properties
 */
interface Props {
}

/**
 * Interface representing component state
 */
interface State {
}

/**
 * Material UI's automated responsive font sizes
 */
const theme = responsiveFontSizes(metaformTheme);

/**
 * App component
 */
class App extends React.Component<Props, State> {

  /**
   * Component did mount life cycle component
   */
  public componentDidMount = () => {
    moment.locale(strings.getLanguage());
  }

  /**
   * Component render method
   */
  public render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline /> 
        <BrowserRouter>
          <div className="App">
            <Switch>
              <Route
                path="/"
                exact={ true }
                render={({ history, location }) => (
                    <FormScreen
                      history={ history }
                      location={ location }
                    />
                )}
              />
            </Switch>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;