/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MetaformTableColumnType {
    Hidden = 'hidden',
    Text = 'text',
    Url = 'url',
    Email = 'email',
    Autocomplete = 'autocomplete',
    Number = 'number',
    Enum = 'enum',
    Date = 'date',
    Time = 'time',
    Html = 'html',
    Button = 'button'
}

export function MetaformTableColumnTypeFromJSON(json: any): MetaformTableColumnType {
    return MetaformTableColumnTypeFromJSONTyped(json, false);
}

export function MetaformTableColumnTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaformTableColumnType {
    return json as MetaformTableColumnType;
}

export function MetaformTableColumnTypeToJSON(value?: MetaformTableColumnType | null): any {
    return value as any;
}

