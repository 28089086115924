/* tslint:disable */
/* eslint-disable */
/**
 * Metaform REST API
 * REST API for Metaform
 *
 * The version of the OpenAPI document: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuditLogEntryType {
    VIEWREPLY = 'VIEW_REPLY',
    LISTREPLY = 'LIST_REPLY',
    MODIFYREPLY = 'MODIFY_REPLY',
    DELETEREPLY = 'DELETE_REPLY',
    CREATEREPLY = 'CREATE_REPLY',
    VIEWREPLYATTACHMENT = 'VIEW_REPLY_ATTACHMENT',
    DOWNLOADREPLYATTACHMENT = 'DOWNLOAD_REPLY_ATTACHMENT',
    EXPORTREPLYPDF = 'EXPORT_REPLY_PDF',
    EXPORTREPLYXLSX = 'EXPORT_REPLY_XLSX'
}

export function AuditLogEntryTypeFromJSON(json: any): AuditLogEntryType {
    return AuditLogEntryTypeFromJSONTyped(json, false);
}

export function AuditLogEntryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLogEntryType {
    return json as AuditLogEntryType;
}

export function AuditLogEntryTypeToJSON(value?: AuditLogEntryType | null): any {
    return value as any;
}

