/**
 * Helper class for handling configurations
 */
export default class Config {

  /**
   * Returns used realm
   * 
   * @returns used realm
   */
  public static getRealm = () => {
    return process.env.REACT_APP_KEYCLOAK_REALM as string;
  }

  /**
   * Returns used metaform id
   * 
   * @returns metaform id
   */
  public static getMetaformId = () => {
    return process.env.REACT_APP_FORM_ID as string;    
  }

  /**
   * Returns used reply mode
   * 
   * @returns used reply mode
   */
  public static getReplyMode = () => {
    return process.env.REACT_APP_REPLY_MODE as string || "CUMULATIVE";
  }
}